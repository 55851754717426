import { Component, HostListener, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'greenMedical';
  constructor(private renderer: Renderer2) {}
  ngOnInit() {
    // Initially check screen size when the component initializes
    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const screenWidth = window.innerWidth;
    const body = document.getElementsByTagName('body')[0];
    // Add the class if screen size is 991px or less, otherwise remove it
  
      this.renderer.addClass(body, 'sidebar-collapse');
    
  }
}
