import { Component, OnInit,ChangeDetectionStrategy,ChangeDetectorRef,Input  } from '@angular/core';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDashboardComponent implements OnInit {
  @Input() data: any;
  constructor(

    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.cdr.detectChanges();
  }

}
