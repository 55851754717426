export enum Role {
  SUPERADMIN = 'super admin',
  ADMIN = 'admin',
  MANAGER = 'manager',
  AGENT = 'agent',
  CUSTOMER = 'customer',
  DOCTOR = 'doctor',
  LAB = 'laboratory',
  AGENCY = 'agency',
  HR = 'hr'
}
