import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {

  constructor() {
  }


  // encrypt and decrypt token
  encryptToken(token) {
    const tokenInfo = CryptoJS.AES.encrypt(JSON.stringify(token), 'my_token').toString()
    localStorage.setItem('currentUser', JSON.stringify(tokenInfo))
  }

  decryptToken() {
    if (localStorage.getItem('currentUser')) {
      const eText = JSON.parse(localStorage.getItem('currentUser'))
      const decryptedWord = CryptoJS.AES.decrypt(eText, 'my_token')
      const decryptedData = JSON.parse(decryptedWord.toString(CryptoJS.enc.Utf8));
      return decryptedData['data']
    } else {
      return null
    }
  }


  // encrypt and decrypt menu list
  encryptMenuList(data : any) {
    const tokenInfo = CryptoJS.AES.encrypt(JSON.stringify(data), 'route_list').toString()
    localStorage.setItem('userRouteList', JSON.stringify(tokenInfo))
  }

  decryptMenuList() {
    if (localStorage.getItem('userRouteList')) {
      const eText = JSON.parse(localStorage.getItem('userRouteList'))
      const decryptedWord = CryptoJS.AES.decrypt(eText, 'route_list')
      let decryptedData =  []
      decryptedData = JSON.parse(decryptedWord.toString(CryptoJS.enc.Utf8));


      return decryptedData
    } else {
      return null
    }
  }

  setMenu(data: any ){
    localStorage.setItem("userRouteList", JSON.stringify(data));
  }

  getMenu(){
    if (localStorage.getItem('userRouteList')) {
      const data = localStorage.getItem("userRouteList");
      const menuData = JSON.parse(data)

      return menuData
    } else {
      return null
    }
  }
}
