import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpInterceptorProvider } from "src/app/shared/interceptor/index";
import { CoreModule } from "./core/core.module";
import { DatePipe } from "@angular/common";
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { ImageCropperModule } from "ngx-image-cropper";
import { DropDownListModule } from "@progress/kendo-angular-dropdowns";
import { GridModule } from "@progress/kendo-angular-grid";
import { NgxPrintModule } from "ngx-print";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PDFExportModule,
    ImageCropperModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    CoreModule,
    DropDownListModule,
    GridModule,
    NgxPrintModule,
  ],
  providers: [HttpInterceptorProvider, DatePipe],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

  bootstrap: [AppComponent],
})
export class AppModule {}
