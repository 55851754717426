import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./core/layout/main/main.component";
import { AuthGuard } from "src/app/shared/guard/auth.guard";
import { HasRoleGuard } from "./shared/guard/hasRole/has-role.guard";
import { Role } from "./shared/models/Role";
import { UserDashboardComponent } from "./core/layout/main/user-dashboard/user-dashboard.component";

const routes: Routes = [
  // default routing
  {
    path: "",
    loadChildren: () =>
      import("./_modules/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./_modules/registration/registration.module").then(
        (m) => m.RegistrationModule
      ),
  },

  // admin user routing
  {
    path: "dashboard",
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./_modules/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "user",
        loadChildren: () =>
          import("./_modules/user-manage/user-manage.module").then(
            (m) => m.UserManageModule
          ),
      },
      {
        path: "agent-user",
        loadChildren: () =>
          import("./_modules/user-manage/agent-list/agent-list.module").then(
            (m) => m.AgentListModule
          ),
      },
      {
        path: "agency-user",
        loadChildren: () =>
          import("./_modules/agency/agency.module").then(
            (m) => m.AgencyModule
          ),
      },
      // test and categoru module url
      {
        path: "test",
        loadChildren: () =>
          import("./_modules/test-category/test/test.module").then(
            (m) => m.TestModule
          ),
      },
      {
        path: "examination-category",
        loadChildren: () =>
          import(
            "./_modules/test-category/examination-category/examination-category.module"
          ).then((m) => m.ExaminationCategoryModule),
      },

      // patients module url
      {
        path: "pcr-patient",
        loadChildren: () =>
          import("./_modules/patients/pcr-patient/pcr-patient.module").then(
            (m) => m.PcrPatientModule
          ),
      },
      {
        path: "medical-patient",
        loadChildren: () =>
          import(
            "./_modules/patients/medical-patient/medical-patient.module"
          ).then((m) => m.MedicalPatientModule),
      },
      {
        path: "orientation-patient",
        loadChildren: () =>
          import(
            "./_modules/patients/orientation-patient/orientation-patient.module"
          ).then((m) => m.OrientationPatientModule),
      },

      // services module url
      {
        path: "service-master",
        loadChildren: () =>
          import(
            "./_modules/services/service-master/service-master.module"
          ).then((m) => m.ServiceMasterModule),
      },
      {
        path: "service-category",
        loadChildren: () =>
          import(
            "./_modules/services/service-category/service-category.module"
          ).then((m) => m.ServiceCategoryModule),
      },
      {
        path: "service-detail",
        loadChildren: () =>
          import(
            "./_modules/services/service-detail/service-detail.module"
          ).then((m) => m.ServiceDetailModule),
      },

      // settings module url
      {
        path: "settings",
        loadChildren: () =>
          import("./_modules/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
      },

      // package module url
      {
        path: "package",
        loadChildren: () =>
          import("./_modules/package/package.module").then(
            (m) => m.PackageModule
          ),
      },

      // agency module url
      {
        path: "agency",
        loadChildren: () =>
          import("./_modules/agency/agency.module").then((m) => m.AgencyModule),
      },

      // get commission list module url
      {
        path: "agent-commission-list",
        loadChildren: () =>
          import(
            "./_modules/commission/agent-commission/agent-commission.module"
          ).then((m) => m.AgentCommissionModule),
      },
      {
        path: "agency-commission-list",
        loadChildren: () =>
          import(
            "./_modules/commission/agency-commission/agency-commission.module"
          ).then((m) => m.AgencyCommissionModule),
      },

      // rbac
      {
        path: "rbac/role",
        loadChildren: () =>
          import("./_modules/rbac/role/role.module").then((m) => m.RoleModule),
      },
      {
        path: "rbac/route",
        loadChildren: () =>
          import("./_modules/rbac/route/route.module").then(
            (m) => m.RouteModule
          ),
      },
      {
        path: "rbac/assignment",
        loadChildren: () =>
          import("./_modules/rbac/assignments/assignments.module").then(
            (m) => m.AssignmentsModule
          ),
      },

      // reports url
      {
        path: "super-report",
        loadChildren: () =>
          import("./_modules/report/super-report/super-report.module").then(
            (m) => m.SuperReportModule
          ),
      },
      {
        path: "report",
        loadChildren: () =>
          import("./_modules/report/report/report.module").then(
            (m) => m.ReportModule
          ),
      },

      // agent and doctor user's patient and commission list url
      {
        path: "commission/:id",
        loadChildren: () =>
          import(
            "./_modules/agent/commission-list/commission-list.module"
          ).then((m) => m.CommissionListModule),
      },
      {
        path: "patient-list/:id",
        loadChildren: () =>
          import("./_modules/agent/patient-list/patient-list.module").then(
            (m) => m.PatientListModule
          ),
      },
      {
        path: "medical-patient",
        loadChildren: () =>
          import(
            "./_modules/patients/medical-patient/medical-patient.module"
          ).then((m) => m.MedicalPatientModule),
      },

      {
        path: "not-acess",
        loadChildren: () =>
          import("./_modules/error-handling/not-access/not-access.module").then(
            (m) => m.NotAccessModule
          ),
      },
      {
        path: "not-found",
        loadChildren: () =>
          import(
            "./_modules/error-handling/page-not-found/page-not-found.module"
          ).then((m) => m.PageNotFoundModule),
      },

      {
        path: "**",
        loadChildren: () =>
          import("./_modules/error-handling/error/error.module").then(
            (m) => m.ErrorModule
          ),
      },
    ],
  },

  //  view report routing routing
  {
    path: "patient-report",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./_modules/viewReport/view-report.module").then(
            (m) => m.ViewReportModule
          ),
      },
    ],
  },

  // //  user routing
  // {
  //   path: 'user',
  //   component: UserDashboardComponent,
  //   canActivate: [AuthGuard, HasRoleGuard],
  //   data: {
  //     // role : Role.agent || Role.doctor
  //     role: [Role.agent, Role.agency, Role.customer, Role.doctor, Role.laboratory]
  //   },
  //   children: [
  //     {
  //       path: "",
  //       loadChildren: () => import('./_modules/user-home/user-home.module').then(m => m.UserHomeModule)
  //     },
  //     {
  //       path: "commission/:id",
  //       loadChildren: () => import('./_modules/agent/commission-list/commission-list.module').then(m => m.CommissionListModule)
  //     },
  //     {
  //       path: "patient-list/:id",
  //       loadChildren: () => import('./_modules/agent/patient-list/patient-list.module').then(m => m.PatientListModule)
  //     },
  //     {
  //       path: "medical-patient",
  //       loadChildren: () => import('./_modules/patients/medical-patient/medical-patient.module').then(m => m.MedicalPatientModule)
  //     },
  //     {
  //       path: "**",
  //       loadChildren: () => import('./_modules/error-handling/error/error.module').then(m => m.ErrorModule)
  //     },
  //   ]
  // },

  //other routing
  {
    path: "server-error",
    loadChildren: () =>
      import("./_modules/error-handling/server-error/server-error.module").then(
        (m) => m.ServerErrorModule
      ),
  },
  {
    path: "not-acess",
    loadChildren: () =>
      import("./_modules/error-handling/not-access/not-access.module").then(
        (m) => m.NotAccessModule
      ),
  },
  {
    path: "not-authorized",
    loadChildren: () =>
      import(
        "./_modules/error-handling/not-authorized/not-authorized.module"
      ).then((m) => m.NotAuthorizedModule),
  },
  {
    path: "network-error",
    loadChildren: () =>
      import(
        "./_modules/error-handling/network-error/network-error.module"
      ).then((m) => m.NetworkErrorModule),
  },
  {
    path: "**",
    loadChildren: () =>
      import(
        "./_modules/error-handling/page-not-found/page-not-found.module"
      ).then((m) => m.PageNotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
