import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { FooterComponent } from './main/footer/footer.component';
import { RouterModule } from '@angular/router';
import { TopbarComponent } from './main/topbar/topbar.component';
import { SidebarComponent } from './main/sidebar/sidebar.component';
import { UserDashboardComponent } from './main/user-dashboard/user-dashboard.component';
import { MenuItemComponent } from './main/sidebar/menu-item/menu-item.component';

@NgModule({
  declarations: [
    MainComponent,
    FooterComponent,
    TopbarComponent,
    SidebarComponent, UserDashboardComponent, MenuItemComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  schemas : [CUSTOM_ELEMENTS_SCHEMA],

})
export class LayoutModule { }
