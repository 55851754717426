
// backgm.bentraytech.com
export let config = {
    base_url : 'https://api.greenmedicalnepal.com/api/web/v1',
    base_main_url : 'https://api.greenmedicalnepal.com/',
    patinet_image_base_url : 'https://api.greenmedicalnepal.com/public/images/patients/',
    patinet_document_base_url : 'https://api.greenmedicalnepal.com/public/images/document/',
    setting_document_base_url : 'https://api.greenmedicalnepal.com/public/images/settings/'

    // base_url : 'https://devapi.greenmedicalnepal.com/api/web/v1',
    // base_main_url : 'https://devapi.greenmedicalnepal.com/',
    // patinet_image_base_url : 'https://devapi.greenmedicalnepal.com/public/images/patients/',
    // patinet_document_base_url : 'https://devapi.greenmedicalnepal.com/public/images/document/',
    // setting_document_base_url : 'https://devapi.greenmedicalnepal.com/public/images/settings/'
}
